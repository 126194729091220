import * as CryptoJS from 'crypto-js';
import { AES } from 'crypto-js';
import { environment } from 'src/environments/environment';

export function encrypt(id: any) {
    return AES.encrypt(id.toString(), environment.key).toString();
}

export function decrypt(id: any) {
    return AES.decrypt(id, environment.key).toString(CryptoJS.enc.Utf8);
}

export function generateRandomPassword() {
    const length = 20; // Longitud de la contraseña
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()'; // Caracteres permitidos
    return Array.from({ length }, () => characters[Math.floor(Math.random() * characters.length)]).join('');
}