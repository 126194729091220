import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { decrypt } from 'src/assets/js/cifrado';
import { environment } from 'src/environments/environment.prod';

@Component({ selector: 'app-root', templateUrl: 'app.component.html', styleUrls: ['app.component.scss'], })

export class AppComponent {
  constructor(private title: Title, private router: Router) {

    const centroMapping = {
      [environment.centro]: environment.centro_codigo,
      [environment.centro_demo]: environment.centro_demo_codigo,
      [environment.centro_cardiopreventivoec]: environment.codigo_cardiopreventivoec,
      [environment.centro_cesamed]: environment.codigo_cesamed,
    };
    const currentDomain = window.location.origin;
    const centroCode = centroMapping[currentDomain];
    // localStorage.setItem('domain', JSON.stringify({ 'url': 'http://localhost:8100', 'code': 'Demo123' }));
    if (centroCode) {
      localStorage.setItem('domain', JSON.stringify({ 'url': currentDomain, 'code': centroCode }));
      this.router.navigateByUrl('login/' + centroCode);
    }
    this.initializeApp();
  }

  initializeApp() {
    let version_storage = localStorage.getItem('version')
    let domain = localStorage.getItem('domain') ? JSON.parse(localStorage.getItem('domain')) : null;
    let versionActual = environment.version;
    if (version_storage) {
      if (version_storage != versionActual) {
        localStorage.clear();
        localStorage.setItem('version', versionActual);
        if (domain) this.domain(domain.code);
        return;
      }
      return;
    }
    localStorage.clear();
    localStorage.setItem('version', versionActual);
    if (domain) this.domain(domain.code);
  }

  domain(code) { this.router.navigateByUrl('login/' + code); }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const title = this.getTitle(this.router.routerState, this.router.routerState.root);
        this.title.setTitle(this.nombre_centro() + ' | ' + title[0]);
      }
    });
  }

  getTitle(state, parent) {
    if (parent && parent.snapshot.data && parent.snapshot.data.title) { return [parent.snapshot.data.title]; }
    if (state && parent) { return this.getTitle(state, state.firstChild(parent)); }
    return [];
  }

  nombre_centro() {
    let nombre_centro = 'Meditech';
    let consultorio = localStorage.getItem('consultorio') ? decrypt(localStorage.getItem('consultorio')) : null;
    if (consultorio) nombre_centro = JSON.parse(consultorio).nombre;
    return nombre_centro;
  }

}

