export const environment = {
  production: false,
  version: '1.2.6', //21-08-2024
  websockets: {
    key: 'bc718eada5f6ce8132b7',
    wsHost: 'demopruebawebsockets.meditech.me',
  },
  key: 'Y2FsdGVkX19sw4u+0bGhxcd0cCkGLtkjwTUkzwnZooc=',
  centro: 'https://pacientes.neurologicinternational.com',
  centro_demo: 'https://pacientes-santiago.meditech.me',
  centro_cardiopreventivoec: 'https://pacientes-cardiopreventivoec.meditech.me',
  centro_cesamed: 'https://pacientes.cesamed.ec',
  centro_codigo: 'NEUROLOGIC_INTERNATIONAL',
  centro_demo_codigo: 'Demo123',
  codigo_cardiopreventivoec: 'CARDIOPREVENTIVOEC',
  codigo_cesamed: 'cesamed',
};

export const baseUrl =
  // 'https://api.meditech.me/' //Producción;
  'https://api-demo.meditech.me/'; // Prueba
  // 'http://localhost/api_meditech/public/';

export const urls = {
  codigo_centro: baseUrl + 'codigo_centro',
  get_areas: baseUrl + 'get_areas',
  actualizar_contrasena: baseUrl + 'actualizar_contrasena',
  modulos: baseUrl + 'modulos',
  login_turnero: baseUrl + 'login_turnero',
  turnos: baseUrl + 'turnos',
  pregunta_frecuente: baseUrl + 'pregunta_frecuente',
  obtener_profesional: baseUrl + 'obtener_profesional',
  datos_doctor: baseUrl + 'datos_doctor',
  obtener_horarios_profesional: baseUrl + 'obtener_horarios_profesional',
  agendamiento_guardar_cita: baseUrl + 'agendamiento_guardar_cita',
  registrar_paciente: baseUrl + 'registrar_paciente',
  consultar_paciente: baseUrl + 'consultar_paciente',
  consultar_paciente_centro: baseUrl + 'consultar_paciente_centro',
  volver_enviar_codigo: baseUrl + 'volver_enviar_codigo',
  get_transactions: baseUrl + 'get_transactions',
  get_recetas: baseUrl + 'get_recetas',
  get_solicitudes: baseUrl + 'get_solicitudes',
  get_archivos: baseUrl + 'get_archivos',
  get_pacientes: baseUrl + 'get_pacientes',
  get_pacientes_institucion: baseUrl + 'get_pacientes_institucion',
  get_ordenes: baseUrl + 'get_ordenes',
  get_invoices: baseUrl + 'get_invoices',
  multisucursal: baseUrl + 'multisucursal',
  terminos_condiciones: baseUrl + 'terminos_condiciones',
  politicas_privacidad: baseUrl + 'politicas_privacidad',
  anular_cita: baseUrl + 'anular_cita',
  reenviar_correo_confirmacion: baseUrl + 'reenviar_correo_confirmacion',
}