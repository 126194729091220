import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { decrypt } from 'src/assets/js/cifrado';

@Injectable({ providedIn: 'root' })

export class AuthCenterGuard implements CanActivate {
	constructor(private router: Router) { }

	canActivate(): boolean {
		const isCode = localStorage.getItem('codigo') != null;
		const isConsultorio = localStorage.getItem('consultorio') != null;

		if (isCode && isConsultorio) {
			const code = decrypt(localStorage.getItem('codigo'));
			this.router.navigateByUrl('login/' + code, { replaceUrl: true });
		}
		return true;
	}
}
