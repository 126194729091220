import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { AuthCenterGuard } from './guards/authCenter.guard';

const routes: Routes = [
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{
		path: 'login', loadChildren: () => import('./inicio/inicio.module').then(m => m.InicioPageModule),
		canActivate: [AuthCenterGuard], data: { title: 'Código centro' },
	},
	{
		path: 'login/:code', loadChildren: () => import('./pages/center/center.module').then(m => m.CenterPageModule),
		canActivate: [AuthGuard], data: { title: 'Código' },
	},
	{
		path: 'registro', loadChildren: () => import('./registro/registro.module').then(m => m.RegistroPageModule),
		canActivate: [AuthGuard], data: { title: 'Registro' },
	},
	{
		path: 'inicio', loadChildren: () => import('./pages/servicios/servicios.module').then(m => m.ServiciosPageModule),
		canActivate: [LoginGuard], data: { title: 'Inicio' },
	},
	{
		path: 'areas-medica', loadChildren: () => import('./pages/find-especialidades/find-especialidades.module').then(m => m.FindEspecialidadesPageModule),
		canActivate: [LoginGuard], data: { title: 'Áreas médicas' }
	},
	{
		path: 'detalle-doctor', loadChildren: () => import('./pages/detalle-doctor/detalle-doctor.module').then(m => m.DetalleDoctorPageModule),
		canActivate: [LoginGuard], data: { title: 'Datos del especialista' }
	},
	{
		path: 'reserva', loadChildren: () => import('./pages/reserva/reserva.module').then(m => m.ReservaPageModule),
		canActivate: [LoginGuard], data: { title: 'Agendar cita' }
	},
	{
		path: 'lista-doctor', loadChildren: () => import('./pages/lista-doctor/lista-doctor.module').then(m => m.ListaDoctorPageModule),
		canActivate: [LoginGuard], data: { title: 'Especialistas' }
	},
	{
		path: 'envio-code', loadChildren: () => import('./pages/envio-code/envio-code.module').then(m => m.EnvioCodePageModule),
		canActivate: [LoginGuard], data: { title: 'Solicitud de agendamiento' }
	},
	{
		path: 'preguntas-respuesta', loadChildren: () => import('./pages/preguntas-respuesta/preguntas-respuesta.module').then(m => m.PreguntasRespuestaPageModule),
		canActivate: [LoginGuard], data: { title: 'Preguntas' }
	},
	{
		path: 'consultas', loadChildren: () => import('./pages/home/consultation/consultation.module').then(m => m.ConsultationPageModule),
		canActivate: [LoginGuard], data: { title: 'Consultas' }
	},
	{
		path: 'recetas', loadChildren: () => import('./pages/home/recipe/recipe.module').then(m => m.RecipePageModule),
		canActivate: [LoginGuard], data: { title: 'Recetas' }
	},
	{
		path: 'solicitudes', loadChildren: () => import('./pages/home/request/request.module').then(m => m.RequestPageModule),
		canActivate: [LoginGuard], data: { title: 'Solicitudes' }
	},
	{
		path: 'adjuntos', loadChildren: () => import('./pages/home/file/file.module').then(m => m.FilePageModule),
		canActivate: [LoginGuard], data: { title: 'Adjuntos' }
	},
	{
		path: 'paciente', loadChildren: () => import('./pages/home/patient/patient.module').then(m => m.PatientPageModule),
		canActivate: [LoginGuard], data: { title: 'Pacientes' }
	},
	{
		path: 'resultados/:type', loadChildren: () => import('./pages/home/result/result.module').then(m => m.ResultPageModule),
		canActivate: [LoginGuard], data: { title: 'Resultados' }
	},
	{
		path: 'facturas', loadChildren: () => import('./pages/home/invoice/invoice.module').then(m => m.InvoicePageModule),
		canActivate: [LoginGuard], data: { title: 'Facturas' }
	},
	{
		path: 'turnero/:code', loadChildren: () => import('./turner/turner.module').then(m => m.TurnerPageModule),
		canActivate: [], data: { title: 'Turnos' }
	},

];

@NgModule({
	imports: [
		BrowserModule,
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
