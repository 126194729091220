import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate {
	constructor(private router: Router) { }

	canActivate() {
		const isAuthenticated = localStorage.getItem('profile') != null;

		if (isAuthenticated) return true;
		this.router.navigateByUrl('/login', { replaceUrl: true });
	}
}
