import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	constructor(private router: Router) { }

	canActivate() {
		const isAuthenticated = localStorage.getItem('profile') != null;

		if (isAuthenticated) return this.router.navigateByUrl('/inicio');
		return true;
	}
}
